<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container fib-container profile-height-navbar">

    <div class="logo-container">
      <a class="navbar-brand center" style="padding: 0;margin:0;" routerLink="/"
        title="Logo {{this.sVariaveis.title}}"><img alt="Logo {{this.sVariaveis.title}}" class="responsive-logo center"
          src="{{logoImage}}">
        <p class="label-logo">{{this.sVariaveis.title}}</p>
      </a>

    </div>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbars-semprevivens"
      aria-controls="navbars-semprevivens" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbars-semprevivens">
      <div>
        <!-- <p class="suporte-desk"><span style="text-transform: capitalize">Suporte 24/7</span>  &nbsp; &nbsp;<a href="#tel">11-35936030</a> &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;<span style="text-transform: capitalize">E-mail</span>  &nbsp; &nbsp;<a href="mailto:atendimento@sempervivens.com.br"><span style="text-transform: lowercase;">atendimento@sempervivens.com.br</span></a></p> -->

        <ul class="list-inline suporte-desk">
          <li class="list-inline-item item-suporte-desk"><span style="text-transform: capitalize">Suporte
              24/7</span> &nbsp; &nbsp;<a href="tel:{{this.sVariaveis.telefoneData}}"
              title=" {{this.sVariaveis.title}} - Telefone do Suporte - {{this.sVariaveis.telefoneLabel}}">{{this.sVariaveis.telefoneLabel}}</a>
          </li>
          <li class="list-inline-item item-suporte-desk"><span style="text-transform: capitalize">E-mail</span> &nbsp;
            &nbsp;<a href="mailto:{{this.sVariaveis.emailAtendimento}}"><span style="text-transform: lowercase;"
                title="{{this.sVariaveis.title}} - E-Mail de Atendimento ao Cliente -  {{this.sVariaveis.emailAtendimento}}">{{this.sVariaveis.emailAtendimento}}</span></a>
          </li>
          <li class="list-inline-item item-suporte-desk-last">
            <ul class="list-inline list-inline-xs">
              <li class="list-inline-item item-suporte-desk-socials" style="margin-right:2px;"><a
                  class="icone icon-sp-facebook" style="color:#3C5A99;" href="{{this.sVariaveis.facebookUrl}}" target="_blank"
                  title="Facebook da {{this.sVariaveis.title}}" aria-label="Facebook" rel="noopener"></a></li>
              <li class="list-inline-item item-suporte-desk-socials"><a class="icone icon-sp-twitter"
                  href="{{this.sVariaveis.twitterUrl}}" target="_blank" title="Twitter da {{this.sVariaveis.title}}" rel="noopener"
                  aria-label="Twitter" style="color:#1DA1F2;"></a></li>
              <li class="list-inline-item item-suporte-desk-socials"><a class="icone icon-sp-instagram" style="  color: #f09433; 
color: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
color: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
color: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );"
                  target="_blank" title="Instagram da {{this.sVariaveis.title}}" aria-label="Instagram" rel="noopener"
                  href="{{this.sVariaveis.instagramUrl}}" rel="noopener"></a></li>
              <li class="list-inline-item item-suporte-desk-socials"><a class="icone icon-sp-whatsapp" target="_blank"
                  title="WhatsApp da {{this.sVariaveis.title}}" href="{{this.sVariaveis.whatsApp}}" aria-label="Whatsapp"
                  style="color:#25D366;" rel="noopener"></a></li>
            </ul>
          </li>
        </ul>


        <ul class="navbar-nav mr-auto">
          <li class="nav-item active"  data-toggle="collapse" data-target=".navbar-collapse.show">
            <a class="nav-link" title="Sobre a {{this.sVariaveis.title}}" routerLink="sobre">Sobre <span
                class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item dropdown" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a class="nav-link dropdown-toggle" href="#" id="dropdown07" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false" title="{{this.sVariaveis.title}} - Serviços">Serviços</a>
            <div class="dropdown-menu" aria-labelledby="dropdown07">
              <a class="dropdown-item" routerLink="servicos/design-responsivo" title="{{this.sVariaveis.title}} - Sites de alta performace">Design
                responsivo</a>
              <a class="dropdown-item" routerLink="servicos/email-corporativo" title="{{this.sVariaveis.title}} - E-Mail Corporativos">E-Mail
                Corporativo</a>
              <a class="dropdown-item" routerLink="servicos/social-media" title="{{this.sVariaveis.title}} - Social Media">Social Media</a>
              <a class="dropdown-item" routerLink="servicos/marketing-digital" title="{{this.sVariaveis.title}} - E-Mail Marketing">Marketing
                Digital</a>
              <a class="dropdown-item" routerLink="servicos/aplicativos-moveis" title="{{this.sVariaveis.title}} - Aplicativos Móveis">Aplicativos
                Móveis</a>
              <a class="dropdown-item" routerLink="servicos/e-commerce" title="{{this.sVariaveis.title}} - E-Commerce">E-Commerce</a>
            </div>
          </li>
          <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a class="nav-link" title="{{this.sVariaveis.title}} - Preços de Nossos Serviços"  routerLink="precos" >Preços</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" title="{{this.sVariaveis.title}} - Artigos" routerLink="artigos">Artigos</a>
          </li>
          <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <a class="nav-link nav-link-last" title="{{this.sVariaveis.title}} - Fale Conosco" routerLink="contato">Contate-nos</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
