import { Injectable } from '@angular/core';
import { AppModule } from '../app.module';

@Injectable({
  providedIn: 'root'
})
export class VariaveisService {
    title = 'SemperVivens';
    telefoneData = '1135936030';
    telefoneLabel = '(11) 3593-6030';
    celularData = '11930868408';
    celularLabel = '(11) 9308-68408';
    emailAtendimento = 'atendimento@sempervivens.com.br';
    emailSuporte = 'suporte@sempervivens.com.br';
    endereco = 'Pedro Costa, 136- Prq. São José \n São Bernardo do Campo - São Paulo\n09812-610';
    clienteID = '14df5966-7737-454d-9ea6-fdf95cd5e498';
    clienteApiFormUrl = 'https://9v3b5xkbv6.execute-api.us-east-1.amazonaws.com/dev/sempervivens-form';
    templateEmailActivateUrl = 'https://sempervivens.com.br/assets/templates/template-email-subscription.txt';
    templateEmailDeactivateUrl = 'https://sempervivens.com.br/assets/templates/template-email-unsubscription.txt';
    clienteApiArtigosUrl = 'https://9v3b5xkbv6.execute-api.us-east-1.amazonaws.com/dev/artigos';
    facebookUrl = 'https://www.facebook.com/sempervivensweb';
    instagramUrl = 'https://www.instagram.com/sempervivensweb';
    twitterUrl = 'https://twitter.com/sempervivensweb';
    whatsApp = 'https://api.whatsapp.com/send?phone=5511930868408';
  constructor() { }
}
