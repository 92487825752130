import { Injectable, Inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class SEOService {

  constructor(private meta: Meta, private router: Router, @Inject(DOCUMENT) private dom) {

    meta.addTags([
      { property: 'og:locale', content: 'pt_BR' },
      { property: 'og:url', content: 'httpś://sempervivens.com.br' + this.router.url },
      { property: 'og:image', content: 'https://sempervivens.com.br/assets/images/logo/logo-sempre-viva-128.png' },
      { property: 'og:image:type', content: 'content="image/png' },
      { property: 'og:image:width', content: '128' },
      { property: 'og:image:height', content: '117' },
      { property: 'og:site_name', content: 'SemperVivens' },
      { property: 'og:title', content: 'SemperVivens - Soluções Web' },
      {
        property: 'og:description', content: 'Design Responsivo Web-Mobile - ' +
          'Emails Corporativos - Social Media - Marketing Digital - Aplicativos Android e IOS - E-Commerce'
      },
      { property: 'og:type', content: 'website' },
    ], true);

  }

  updateTagProperty(tagName: string, tagContent: string) {
    this.meta.updateTag({ property: tagName, content: tagContent });

    if ( document.querySelector('[property=\'article:section\']')) {
      document.querySelector('[property=\'article:section\']').remove();
      document.querySelector('[property=\'article:author\']').remove();
      document.querySelector('[property=\'article:published_time\']').remove();
    }
  }
  addTagProperty(tagName: string, tagContent: string) {
    this.meta.addTag({ property: tagName, content: tagContent }, true);

  }
  removeTagProperty(tagName: string) {
    this.meta.removeTag(tagName);

  }

  createCanonicalURL(url: string) {
    const el: HTMLCollection = (this.dom.head.children);

    for (let index = 0; index < el.length; index++) {
      const element = el[index];
      switch (element.getAttribute('rel')) {
        case 'canonical':
          this.dom.head.removeChild(element);
          break;

        default:
          break;
      }


    }



    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    const host = window.location.protocol + '//' + window.location.host;
    link.setAttribute('href', host + url);
 }

}
