import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { VariaveisService } from './variaveis.service';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor(private http: HttpClient, private servicoVariaveis: VariaveisService) {

  }


  postForms(formData: string) {
    const body = formData;
    const url = this.servicoVariaveis.clienteApiFormUrl;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this
      .http
      .post(`${url}/`, body, { headers });
  }


}
