import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WebpService {

  constructor() { }

  private canUseWebP() {
    const elem = document.createElement('canvas');

    if (!!(elem.getContext && elem.getContext('2d'))) {
      // was able or not to get WebP representation
      return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }

    // very old browser like IE 8, canvas not supported
    return false;
  }

  public getCompatibleImageFormatExtension() {
    let out: string;
    if (this.canUseWebP()) {

      out = '.webp';

    } else {
      out = '.png';
    }

    return out;
  }

}
