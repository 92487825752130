<footer class="section footer-sempervivens">
    <div class="footer-footer-sempervivens">
        <div class="container">
            <div class="row row-50 align-items-sm-end justify-content-sm-center justify-content-lg-start">
                <div class="col-lg-6">
                    <div class="footer-default-sempervivens">
                        <div class="unit flex-sm-row">
                            <div class="sp__left"><i class="icone icon-sp-headphone" style="font-size: 55px;"
                                    aria-hidden="true"></i></div>
                            <div class="sp__body"><a class="link link-lg" href="tel:{{this.sVariaveis.telefoneData}}"
                                    title="{{this.sVariaveis.title}} - Suporte {{this.sVariaveis.telefoneLabel}}">{{this.sVariaveis.telefoneLabel}}</a>
                                <p style="font-size: 13px;">Nosso suporte esta sempre disponível 24horas dos 365 dias do
                                    ano.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-10 col-lg-6">
                    <div class="group-md">
                        <h3>Assine</h3>
                        <p class="large">Receba nossas atualizações e ofertas</p>
                    </div>
                    <div [hidden]="submitted">

                        <form class="form-inline form-assine needs-validation" (ngSubmit)="onSubmit()"
                            #footerForm="ngForm">

                            <label class="sr-only" for="inlineFormEmail">E-Mail</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text  form-group"><img alt="SemperVivens Logo"
                                            class="responsive-logo center" style="width: 20px;height: auto;" 
                                            src="{{logoImage}}"></div>
                                </div>

                                <label for="email" style="display: none">Assinaturas: </label>
                                <input type="email" class="form-control" id="inlineFormEmail"
                                    placeholder="email@email.com" name="email" required [(ngModel)]="model.email"
                                    #email="ngModel" ngModel
                                    [email]="true">
                                <div [hidden]="email.valid || email.pristine" class="tooltip-validation-error">
                                    Precisamos de seu email válido para enviar novidades :(
                                </div>
                                <button type="submit" id="submit-button" class="btn btn-primary"
                                    [disabled]="!footerForm.form.valid">Assinar<span id="submit-icon"></span></button>

                            </div>


                        </form>
                    </div>
                    <div [hidden]="!submitted">
                        
                        <div class="alert alert-light text-center" role="alert">
                            
                            Sucesso, verifique seu email para ativar a assinatura!
                        </div>
                    </div>
                </div>
            </div>

            <div class="row row-50 justify-content-md-center justify-content-lg-start justify-content-xl-between">
                <div class="col-md-5 col-lg-3">
                    <p class="heading-bordered">Sobre-nós</p>
                    <div class="footer-divisor"></div>
                    <p class="footer-left-lead">Nossa companhia vem desenvolvendo softwares corporativos de alta
                        qualidade e performace desde 2008.</p>
                    <ul class="list-inline list-inline-xs list-socials">
                        <li class="list-inline-item item-suporte-desk-socials"><a
                                class="icone icon-sp-facebook" style="color:#3C5A99;"
                                href="{{this.sVariaveis.facebookUrl}}" target="_blank"  aria-label="Facebook" rel="noopener"></a></li>
                        <li class="list-inline-item item-suporte-desk-socials"><a
                                class="icone icon-sp-twitter"  aria-label="twitter" href="{{this.sVariaveis.twitterUrl}}" target="_blank"
                                style="color:#1DA1F2;" rel="noopener"></a></li>
                        <li class="list-inline-item item-suporte-desk-socials"><a
                                class="icone icon-sp-instagram" style="  color: #f09433; 
color: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
color: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
color: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );"
                                href="{{this.sVariaveis.instagramUrl}}" target="_blank" aria-label="Instagram" rel="noopener"></a></li>
                        <li class="list-inline-item item-suporte-desk-socials last-item-footer-socials"><a
                                class="icone icon-sp-whatsapp" href="{{this.sVariaveis.whatsApp}}" target="_blank"
                                style="color:#25D366;" aria-label="WhatsApp" rel="noopener"></a></li>
                    </ul>
                </div>
                <div class="col-md-5 col-lg-4 col-xl-3">
                    <p class="heading-bordered">Nossos Contatos</p>
                    <div class="footer-divisor"></div>


                    <ul class="footer-contacts list-unstyled">
                        <li class="footer-contact-endr">
                            <div class="footer-contact-info">
                                <a href="https://goo.gl/maps/Liv6nqjg3LmKNuWd8" target="_blank" rel="noopener"
                                    style="white-space: pre-line;"
                                    title="{{this.sVariaveis.title}} Endereço: {{this.sVariaveis.endereco}}"><i
                                        class="icone icon-sp-location2" aria-hidden="true"></i>{{this.sVariaveis.endereco}}
                                </a></div>
                        </li>
                        <li class="footer-contact-tel">

                            <div class="footer-contact-info">
                                <a href="tel:{{this.sVariaveis.telefoneData}}"
                                    title="{{this.sVariaveis.title}} - Fixo {{this.sVariaveis.telefoneLabel}}"
                                    class="formingHrefTel"><i class="icone icon-sp-phone" aria-hidden="true"
                                        style="font-size: 22px"></i>{{this.sVariaveis.telefoneLabel}}</a>, <a
                                    href="tel:{{this.sVariaveis.celularData}}"
                                    title="{{this.sVariaveis.title}} - Celular {{this.sVariaveis.celularLabel}}"
                                    class="formingHrefTel"><i class="icone icon-sp-mobile" aria-hidden="true"
                                        style="font-size: 22px"></i>{{this.sVariaveis.celularLabel}}</a>
                            </div>
                        </li>
                        <li>

                            <div class="footer-contact-info text-top">
                                <a href="mailto:{{this.sVariaveis.emailAtendimento}}" style="white-space: pre-line;"
                                    title="{{this.sVariaveis.title}} - E-Mail de Atendimento {{this.sVariaveis.emailAtendimento}}"><i
                                        class="icone icon-sp-mail text-middle"
                                        aria-hidden="true"></i>{{this.sVariaveis.emailAtendimento}}</a>
                            </div>
                        </li>
                    </ul>


                </div>
                <div class="col-md-10 col-lg-5 col-xl-4">
                    <p class="heading-bordered">Últimos artigos</p>
                    <div class="footer-divisor"></div>
                    <div class="row row-5">
                        <div class="col-sm-12">
                            <ul class="footer-articles list-unstyled">
                                <li *ngFor="let artigo of martigos"> 
                                     <a href="artigos/{{artigo.slug}}" title="{{this.sVariaveis.title}} - {{artigo.title}}"
                                        routerLinkActive="router-link-active"><i class="icone icon-sp-chevron-small-right"></i>{{artigo.titulo}}</a>
                                <li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom">
        <div class="container">
            <div class="footer-sempervivens-inner row ">


                <ul class="direitos list-inline col-sm align-self-center" style="padding: 0;margin: 0;">
                    <li><span>©&nbsp; </span><span class="copyright-year">
                            {{today | date:'y'}}</span><span>&nbsp;</span><span>{{this.sVariaveis.title}}</span><span>.&nbsp;</span><a
                            routerLink="/privacidade"
                            title="{{this.sVariaveis.title}} - Politica de Privacidade">Politica de Privacidade</a>

                            &nbsp;|&nbsp;
                            <a
                            routerLink="/cancelar-assinatura"
                            title="{{this.sVariaveis.title}} - Cancelar assinatura">Cancelar assinatura</a>
                    </li>
                </ul>

                <ul class="lista-separada list-inline col-sm align-self-center ">
                    <li class="list-inline-item"><a [routerLink]="['faq']" 
                            title="{{this.sVariaveis.title}} - Perguntas e Respostas">FAQ</a></li>

                    <li class="list-inline-item "><a routerLink="/suporte"
                            title="{{this.sVariaveis.title}} - Página de Suporte">Suporte</a></li>
                </ul>
            </div>
        </div>
    </div>
</footer>