import { Component, OnInit, HostListener } from '@angular/core';
import AOS from 'aos';
import { SEOService } from './services/seo.service';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';

declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'SemperVivens';

constructor(private seo: SEOService, private router: Router) {

  this.router.events.subscribe((event: any) => {
    // console.log(event);
    switch (true) {
      case event instanceof NavigationStart: {
        // console.log(event.url);
        this.seo.createCanonicalURL(event.url);
        break;
      }

      case event instanceof NavigationEnd:
      case event instanceof NavigationCancel:
      case event instanceof NavigationError: {

        break;
      }
      default: {
        break;
      }
    }});



}

  ngOnInit(): void {
    const scrollEventHandler = () => {

      window.scroll(0, window.pageYOffset);
    };

    window.addEventListener('scroll', scrollEventHandler, false);

    $(document).ready(() => {


      $(() => {

        const $body = $(document);
        $body.bind('scroll', () => {
          // "Disable" the horizontal scroll.
          if ($body.scrollLeft() !== 0) {
            $body.scrollLeft(0);
          }
        });

      });

      AOS.init();
    });
  }





}


