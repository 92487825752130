import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { WebpService } from '../services/webp.service';
import { VariaveisService } from '../services/variaveis.service';
declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  providers:  []
})



export class NavigationComponent implements OnInit {
  sVariaveis: VariaveisService;
    logoImage: string;
  constructor(@Inject(VariaveisService) servicoVariaveis: VariaveisService, @Inject(WebpService) webpService: WebpService) {

    this.sVariaveis = servicoVariaveis;
    this.logoImage = '../../assets/images/logo/logo-sempre-viva-128' + webpService.getCompatibleImageFormatExtension();

  }

/*   @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    const navbar: HTMLElement = document.querySelector('.navbar');
    const sticky = navbar.offsetTop;
    if (window.pageYOffset >= sticky) {
      navbar.classList.add('sticky');
    } else {
      navbar.classList.remove('sticky');
    }
  } */


  ngOnInit() {


    $('.nav a').on('click', () => {

      $('.navbar-toggler').click();
  });

  }

}
