export class Assinante {
    constructor(
        public email: string,
        // tslint:disable-next-line: variable-name
        public tp_form: string,
        public id: string,
        public templateEmailActivateUrl: string
    ) {

    }
}
