

<app-navigation></app-navigation>

      <div id="loader" class="loader-component">
          <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="loading"></div>
        <span class="sr-only">Carregando...</span>
      </div>

<router-outlet>

</router-outlet>
<app-footer></app-footer>

