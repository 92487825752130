import { Injectable } from '@angular/core';
import { Artigos } from '../models/artigos';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { VariaveisService } from './variaveis.service';
@Injectable({
  providedIn: 'root'
})
export class ArtigosService {


  constructor(private http: HttpClient, private servicoVariaveis: VariaveisService) { }
  public getAll(lastEvaluatedKey: string) {


    let param: any;

    param = {clientID: this.servicoVariaveis.clienteID};

    if (lastEvaluatedKey !== null) {
      param = {clientID: this.servicoVariaveis.clienteID, lastEvaluatedKey};

    }

    const url = this.servicoVariaveis.clienteApiFormUrl;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this
      .http
      .get(this.servicoVariaveis.clienteApiArtigosUrl, {params: param});
  }


  public getArtigo(slug: string) {
    let param: any;
    param = {clientID: this.servicoVariaveis.clienteID, slug};



    const url = this.servicoVariaveis.clienteApiFormUrl;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this
      .http
      .get(this.servicoVariaveis.clienteApiArtigosUrl, {params: param});
  }
}
