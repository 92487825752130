import { Component, OnInit, Inject } from '@angular/core';
import { VariaveisService } from '../services/variaveis.service';
import { Assinante } from '../models/assinante';
import { WebpService } from '../services/webp.service';
import { FormService } from '../services/form.service';
import { ArtigosService } from '../services/artigos.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtilsService } from '../services/utils.service';
import art from './../../assets/artigos/5artigos.json';
declare var $: any;

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    providers: []
})

export class FooterComponent implements OnInit {
    today: number = Date.now();

    sVariaveis: VariaveisService;
    logoImage: string;

    model = new Assinante('', '', '', '');

    submitted = false;
    public martigos: any;

    // TODO: Remove this when we're done
    get diagnostic() { return JSON.stringify(this.model); }

    constructor(@Inject(VariaveisService) servicoVariaveis: VariaveisService, @Inject(WebpService) webpService: WebpService,
                private formService: FormService, private artigoService: ArtigosService,
                private util: UtilsService, private http: HttpClient) {

        this.sVariaveis = servicoVariaveis;
        this.logoImage = '../../assets/images/logo/logo-sempre-viva-128' + webpService.getCompatibleImageFormatExtension();

    }
    onSubmit() {

        const submitIcon: HTMLElement = document.querySelector('#submit-icon');
        submitIcon.innerHTML = '';
        submitIcon.setAttribute('class', 'submit-icon');
        const submitButton: HTMLElement = document.querySelector('#submit-button');
        submitButton.setAttribute('style', 'color:black;');
        submitButton.setAttribute('disabled', 'disabled');
        this.model.tp_form = 'assinatura';
        this.model.id = this.sVariaveis.clienteID;
        this.model.templateEmailActivateUrl = this.sVariaveis.templateEmailActivateUrl;
        this.formService.postForms(JSON.stringify(this.model)).subscribe((data) => {
            const obj = JSON.parse(JSON.stringify(data));

            if (obj.message === 'success') {

                this.submitted = true;
            } else {

                this.submitted = false;
            }

            submitIcon.removeAttribute('class');
            submitButton.removeAttribute('disabled');
            // console.log(e);

        });

    }

    ngOnInit() {


        if (this.util.getCookie('5artigos') !== '') {
            this.martigos = JSON.parse(this.util.getCookie('5artigos'));
        } else {
            this.getArtigos().subscribe((data) => {
                this.martigos = data;
                this.util.setCookie('5artigos', JSON.stringify(data), 1);
            });

        }

    }

    getArtigos() {

        const url = './../../assets/artigos/5artigos.json';
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json');
        return this
            .http
            .get(`${url}`);
    }


}
