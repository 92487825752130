import { NgModule } from '@angular/core';

import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
/* import { HomeContentComponent } from './pages_bkp/home/home-content.component';
import { SobreComponent } from './pages_bkp/sobre/sobre.component';
import { PoliticaDePrivacidadeComponent } from './pages_bkp/politica-de-privacidade/politica-de-privacidade.component';
import { EmailCorporativoPorqueComponent } from './artigos/email-corporativo-porque/email-corporativo-porque.component';
import { PrecosComponent } from './pages_bkp/precos/precos.component';
 */
/* const routes: Routes = [
    { path: '', component: HomeContentComponent },
    { path: 'sobre', component: SobreComponent },
    { path: 'precos', component: PrecosComponent },
    { path: 'politica-de-privacidade', component: PoliticaDePrivacidadeComponent },
    { path: 'artigos/email-corporativo-porque', component: EmailCorporativoPorqueComponent },
];
 */

const routes: Routes = [

  {path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)},
  {path: 'sobre', loadChildren: () => import('./pages/sobre/sobre.module').then(m => m.SobreModule)},
  {path: 'precos', loadChildren: () => import('./pages/precos/precos.module').then(m => m.PrecosModule)},
  {path: 'contato', loadChildren: () => import('./pages/contato/contato.module').then(m => m.ContatoModule)},
  {path: 'suporte', loadChildren: () => import('./pages/suporte/suporte.module').then(m => m.SuporteModule)},
  {path: 'faq', loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule)},
  {path: 'privacidade', loadChildren: () => import('./pages/privacidade/privacidade.module').then(m => m.PrivacidadeModule)},
  {path: 'servicos/design-responsivo', loadChildren: () => import('./pages/servicos/design-responsivo/design-responsivo.module')
  .then(m => m.DesignResponsivoModule)},
  {path: 'servicos/email-corporativo', loadChildren: () => import('./pages/servicos/email-corporativo/email-corporativo.module')
  .then(m => m.EmailsCorporativoModule)},
  {path: 'servicos/social-media', loadChildren: () => import('./pages/servicos/social-media/social-media.module')
  .then(m => m.SocialMediaModule)},
  {path: 'servicos/marketing-digital', loadChildren: () => import('./pages/servicos/marketing-digital/marketing-digital.module')
  .then(m => m.MarketingDigitalModule)},
  {path: 'servicos/aplicativos-moveis', loadChildren: () => import('./pages/servicos/aplicativos-moveis/aplicativos-moveis.module')
  .then(m => m.AplicativosMoveisModule)},
  {path: 'servicos/e-commerce', loadChildren: () => import('./pages/servicos/e-commerce/e-commerce.module')
  .then(m => m.ECommerceModule)},
  {path: 'email-ativado', loadChildren: () => import('./pages/email-ativado/email-ativado.module')
  .then(m => m.EmailAtivadoModule)},
  {path: 'cancelar-assinatura', loadChildren: () => import('./pages/cancelar-assinatura/cancelar-assinatura.module')
  .then(m => m.CancelarAssinaturaModule)},
  {path: 'artigos', loadChildren: () => import('./artigos/artigos.module')
  .then(m => m.ArtigosModule)},
  {path: 'artigos/:id', loadChildren: () => import('./artigos/artigo/artigo.module')
  .then(m => m.ArtigoModule)},
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent}

];

@NgModule({
  declarations: [],
  exports: [ RouterModule ],
  imports: [ RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  }) ]
})
export class AppRoutingModule { }
